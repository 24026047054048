<template>
    <div class="p-8 text-xs">
        <div class="grid grid-cols-12 gap-8">
            <div class="col-span-8">
                <!-- Riwayat Kontrak Section -->
                <div class="rounded-2xl bg-white p-6 shadow-md mb-6">
                    <div class="flex justify-between items-center mb-4">
                        <h2 class="text-lg font-semibold">Data Riwayat Kontrak</h2>
                        <input type="text" class="border border-gray-300 p-2 rounded-lg" placeholder="Search" />
                    </div>
                    <template v-if="user.role_id !== 32">
                        <table class="w-full border-t border-gray-200 text-left">
                            <thead>
                                <tr>
                                    <th class="p-4 cursor-pointer" @click="sortBy('start_date')">
                                        Tanggal Mulai
                                        <span v-if="sortKey === 'start_date'">
                                            {{ sortOrder === 'desc' ? '↓' : '↑' }}
                                        </span>
                                    </th>
                                    <th class="p-4 cursor-pointer" @click="sortBy('expired_date')">
                                        Tanggal Berakhir
                                        <span v-if="sortKey === 'expired_date'">
                                            {{ sortOrder === 'desc' ? '↓' : '↑' }}
                                        </span>
                                    </th>
                                    <th class="p-4">Catatan</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(dataContract, index) in sortedContracts" :key="index">
                                    <td class="p-4">{{ formatDate(dataContract.start_date) }}</td>
                                    <td class="p-4">{{ formatDate(dataContract.expired_date) }}</td>
                                    <td class="p-4">{{ dataContract.note }}</td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <template v-else>
                        <div class="flex justify-center items-center flex-col text-gray-500 py-6">
                            <i class="fas fa-exclamation-circle text-4xl mb-2"></i>
                            <p>No Data Available</p>
                        </div>
                    </template>
                </div>
                <div class="rounded-2xl bg-white p-6 shadow-md">
                    <div class="flex justify-between items-center mb-4">
                        <h2 class="text-lg font-semibold">Data Pengguna</h2>
                        <input type="text" class="border border-gray-300 p-2 rounded-lg" placeholder="Search" />
                    </div>
                    <template v-if="user.role_id !== 32">
                        <table class="w-full border-t border-gray-200 text-left">
                            <thead>
                                <tr>
                                    <th class="p-4">Nama</th>
                                    <th class="p-4">Email</th>
                                    <th class="p-4">Username</th>
                                    <th class="p-4"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in paginatedRows" :key="index">
                                    <td class="p-4">{{ row.name }}</td>
                                    <td class="p-4">{{ row.email }}</td>
                                    <td class="p-4">{{ row.username }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <div class="flex justify-between items-center mt-4">
                            <button class="px-4 py-2 bg-gray-300 rounded-lg" :disabled="currentPage === 1"
                                @click="currentPage--">
                                Previous
                            </button>
                            <span>Page {{ currentPage }} of {{ totalPages }}</span>
                            <button class="px-4 py-2 bg-gray-300 rounded-lg" :disabled="currentPage === totalPages"
                                @click="currentPage++">
                                Next
                            </button>
                        </div>
                    </template>

                    <template v-else>
                        <div class="flex justify-center items-center flex-col text-gray-500 py-6">
                            <i class="fas fa-exclamation-circle text-4xl mb-2"></i>
                            <p>No Data Available</p>
                        </div>
                    </template>
                </div>


            </div>
            <!-- Sidebar Reseller Info -->
            <div class="col-span-4">
                <template v-if="user.role_id !== 32">
                    <div class="rounded-2xl bg-white p-6 shadow-md text-center">
                        <img :src="data.foto ? `${default_url}${data.foto}` : require('@/assets/default.png')"
                            class="w-40 h-40 rounded-full mx-auto mb-4" @click="$refs.foto_profile.click()" />
                        <p class="col-span-9 p-2 rounded text-xs">{{ data.name || '-' }}</p>
                        <p class="text-gray-500 mb-4">{{ data.email || '-' }}</p>
                        <div class="text-left space-y-2">
                            <p><strong>Lama Kontrak:</strong> {{ data.days_expired }} Hari</p>
                            <p><strong>Nomor Telepon:</strong> {{ data.phone || '-' }}</p>
                            <p><strong>Alamat:</strong> {{ data.address || '-' }}</p>
                            <p><strong>Nama Perusahaan:</strong> {{ data.company_name || '-' }}</p>
                            <p><strong>Website:</strong>
                                <a :href="data.website || '-'" class="text-blue-500">
                                    {{ data.website || '-' }}
                                </a>
                            </p>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="mt-4 text-center">
                        <i class="fa-solid fa-user-check text-green-500 text-xl"></i> <!-- Ikon untuk pelanggan -->
                        <p class="text-xs text-gray-500 mt-2">Hanya dapat dilihat oleh customer</p>
                    </div>
                </template>
            </div>

        </div>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import contractComponenrt from '../../components/contract.vue'
import moment from 'moment'
import globalMixin from '../../mixin/global'

export default {
    data() {
        return {
            data: {
                name: '',
                email: '',
                company_name: '',
                address: '',
                phone: '',
                start_date: '',
                end_date: '',
                foto: null,
                start_date: '', // ganti dengan data tanggal yang sebenarnya
                end_date: '', // ganti dengan data tanggal yang sebenarnya
            },
            dataContract: [],
            rows: [], // Data pengguna
            currentPage: 1, // Halaman aktif
            itemsPerPage: 5, // Jumlah item per halaman
            dataContract: [], // Data kontrak
            sortKey: '', // Kolom yang sedang diurutkan
            sortOrder: 'desc', // Urutan default (descending)
            addData: {
                email: '',
                name: '',
                company_name: '',
                phone: '',
                expired_date: '',
                foto: '',
                website: '',
                prov_id: '',
                city_id: '',
                dis_id: '',
                address: '',
                start_date: moment().format('YYYY-MM-DD').toString(),
                note: '',
            },
            rows: [],
            default_url: process.env.VUE_APP_IMAGES,
        };
    },
    mixins: [globalMixin],
    methods: {
        getData() {
            this.axios.get('v1/setting', this.config)
                .then(response => {
                    console.log(response.data.data, 'success');
                    this.data = response.data.data;
                })

            this.axios.get('v1/users', this.config)
                .then(response => {
                    this.rows = response.data.rows;
                    // console.log(response.data.rows.role , 'success')
                })
                .catch(this.errorResponse);
        },
        getDataContract() {
            this.axios.get('v1/contract', this.config)
                .then(response => {
                    this.dataContract = response.data.data;
                    // console.log(response.data.data, 'success');
                })
                .catch(this.errorResponse);
        },
        formatDate(date) {
            if (!date) return null;
            const options = { day: 'numeric', month: 'short', year: 'numeric' };
            return new Intl.DateTimeFormat('en-GB', options).format(new Date(date));
        },
        shouldShowUserData() {
            return this.user?.role_id !== 32; // Jika role_id 32, maka komponen tidak ditampilkan
        },
        sortBy(key) {
            if (this.sortKey === key) {
                // Jika kolom yang sama di klik, toggle order
                this.sortOrder = this.sortOrder === 'desc' ? 'asc' : 'desc';
            } else {
                // Set kolom baru untuk diurutkan
                this.sortKey = key;
                this.sortOrder = 'desc';
            }
        },
        async postData() {
            let data = new FormData()
            var request_form = this.addData
            await Object.keys(this.addData).forEach(function (key, val) {
                console.log(key)
                if (key != 'foto') {
                    data.append(key, request_form[key])

                }
            });

            if (this.foto) {
                data.append('foto', this.foto)
            } else {
                data.append('foto', '')
            }

            this.axios.put('v1/customer/' + this.$route.params.id, data, this.config)
                .then((ress) => {
                    this.$snack.success({
                        text: ress.data.message,

                    })
                    this.$router.push('/customer')
                })
                .catch(err => {
                    if (err.response.status == 400) {
                        this.$refs.form.setErrors(err.response.data.message)
                    }
                    this.errorResponse(err)
                })

        },
        ...mapActions({ setUser: 'auth/setUser' }),
    },
    computed: {
        ...mapGetters({
            user: 'auth/user',
            detail: 'auth/detail',
            token: 'auth/token',
        }),
        contractDurationInYears() {
            const startDate = new Date(this.data.start_date);
            const endDate = new Date(this.data.end_date);

            const durationInMilliseconds = endDate - startDate;
            const durationInYears = durationInMilliseconds / (1000 * 60 * 60 * 24 * 365.25); // Menggunakan 365.25 untuk memperhitungkan tahun kabisat

            return durationInYears.toFixed(2); // Mengembalikan hasil dalam 2 angka desimal
        },
        totalPages() {
            return Math.ceil(this.rows.length / this.itemsPerPage); // Total halaman
        },
        paginatedRows() {
            const start = (this.currentPage - 1) * this.itemsPerPage;
            const end = start + this.itemsPerPage;
            return this.rows.slice(start, end); // Data yang ditampilkan di halaman saat ini
        },
        sortedContracts() {
            if (!this.sortKey) return this.dataContract;

            return [...this.dataContract].sort((a, b) => {
                const valueA = a[this.sortKey];
                const valueB = b[this.sortKey];

                if (this.sortOrder === 'desc') {
                    return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
                } else {
                    return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
                }
            });
        },
    },
    created() {
        this.getData();
        this.getDataContract();
        this.getDataContract();
        this.shouldShowUserData = this.shouldShowUserData();
    },
    components: {
        contractComponenrt
    }
};
</script>

<style scoped>
.text-xs {
    font-size: 12px;
}
</style>
